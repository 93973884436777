/* istanbul ignore file */

import type { LineChartData } from './Charts';

enum DateSegmentTypeEnum {
  DAILY,
  MTD,
  QTD,
  NA,
  TODAY,
  YTD,
}

export enum ViewAsRoleEnum {
  EXEC,
  CORP,
  GRP,
  DIV,
  REG,
  BRCH,
  TEAM,
  CAM,
  CUST,
  REP,
  PRD_GRP,
  PRD_GRP_01,
  PRD_GRP_02,
  LOC,
  BILLTO,
}

export enum RoleGroupEnum {
  CORP,
  DIV,
  REG,
  TEAM,
  CAM,
  BRCH,
  CUST,
  REP,
  PRD_GRP,
  PRD_GRP_01,
  PRD_GRP_02,
}

enum DataTypeEnum {
  S,
  GP,
  SGP,
}

enum SalesKeysEnum {
  date,
  quantity,
  dollars,
  transactions,
}

enum ProfitsKeysEnum {
  date,
  gp,
  gpPercent,
}

// TODO: use them as enums and not strings
export type DateSegmentType = keyof typeof DateSegmentTypeEnum;

export type ViewAsRoleType = keyof typeof ViewAsRoleEnum;

export type RoleGroupType = keyof typeof RoleGroupEnum;

export type DataType = keyof typeof DataTypeEnum;

export type SalesKeys = keyof typeof SalesKeysEnum;

export type ProfitKeys = keyof typeof ProfitsKeysEnum;

export type SalesTrend = {
  [key in SalesKeys]: number;
};

export type ProfitTrend = {
  [key in ProfitKeys]: number;
};

export interface SummaryItemInput {
  amount: number;
  change: number;
  Name: string;
  salesTrend?: SalesTrend[];
  profitTrend?: ProfitTrend[];
}

export interface SalesTrendExt extends SalesTrend {
  dateType?: string;
  show?: boolean;
  busDate?: number | undefined;
}

export interface ProfitTrendExt extends ProfitTrend {
  dateType?: string;
  show?: boolean;
  busDate?: number | undefined;
}

export interface SummaryItemOutput {
  amount: number;
  change: number;
  Name: string;
  trend?: LineChartData[];
  currencyType?: string;
  text?: string;
  hideValue?: boolean;
}

export interface WebPerformanceOutput {
  nonWebGPPercent: number;
  webPercentOfTotalSales: number;
  webVsNonWebGPBasisPointsDiff: number;
  webVsNonWebGPDiff: number;
  profit: SummaryItemOutput[];
  sales: SummaryItemOutput[];
}

// TODO: use this enum everywhere else instead of hardcoded strings
export enum DrillDownFieldsEnum {
  'Sales' = 'Sales',
  'GP' = 'GP',
  'GP %' = 'GP %',
  'Avg Daily' = 'Avg Daily',
  'Orders' = 'Orders',
  'Pick12 Count' = 'Pick12 Count',
  'Total Pick12 Count' = 'Total Pick12 Count',
  'Pick12 %' = 'Pick12 %',
  'Unbilled' = 'Unbilled',
  'Avg Sales Acct' = 'Avg Sales Acct',
  'Last Year Sales' = 'Last Year Sales',
  'Yoy Growth' = 'Yoy Growth',
  '% of Total Sales' = '% of Total Sales',
  '% Active WB' = '% Active WB',
  'Total Sales' = 'Total Sales',
  'Monthly Total Sales' = 'Monthly Total Sales (Incl Summary Billing)',
  'Commit' = 'Commit',
  'Commit %' = 'Commit %',
  'Projected' = 'Projected',
  'Approved' = 'Approved',
  'Pending' = 'Pending',
  'PendingCount' = 'PendingCount',
  'Guaranteed Paid' = 'Guaranteed Paid',
  'Overage' = 'Overage',
  'ApprovedCount' = 'ApprovedCount',
  'DisApprovedCount' = 'DisApprovedCount',
  'DisApprovedSales' = 'DisApprovedSales',
  'MaxApprovedSales' = 'MaxApprovedSales',
  'MaxOverage' = 'MaxOverage',
  'Effective Date' = 'Effective Date',
  'Expiration Date' = 'Expiration Date',
}

export interface TableFields {
  key: DrillDownFieldsEnum;
  id: string;
  disabledLevels?: string[];
  disabledNonToday?: boolean;
}

export const SalesDrillDownFields = [
  DrillDownFieldsEnum.Sales,
  DrillDownFieldsEnum.GP,
  DrillDownFieldsEnum['GP %'],
  DrillDownFieldsEnum['Avg Daily'],
  DrillDownFieldsEnum.Orders,
  DrillDownFieldsEnum.Unbilled,
];

export interface DrillDownItem {
  custMiLoc: string;
  nameCd: string;
  productCatCode: string;
  name: string;
  natlAcctNo: string;
  miLoc: string;
  miLocName: string;
  disabledDrilldown?: boolean;
  sales?: number;
  salesChange?: number;
  transactions?: number;
  transactionsChange?: number;
  avgDaily?: number;
  avgDailyChange?: number;
  gpAmount?: number;
  gpChange?: number;
  gpPercentAmount?: number;
  gpPercentChange?: number;
  unbilled?: number;
  pick12CustomerCount?: number;
  totalPick12CustCount?: number;
  pick12Pct?: number;
  salesTrend?: SalesTrend[];
  profitTrend?: ProfitTrend[];
  avgSalesPerAcct?: number;
  previousSales?: number;
  currencyType?: string;
  customerPick12?: boolean;
  activeWebRegistered?: number;
  webPercentOfTotalSales?: number;
  overage?: number;
  commitCurrentSales?: number;
  commitPercentage?: number;
  projectedSales?: number;
  approvedSales?: number;
  pendingSales?: number;
  guaranteed?: string;
  customerName: string;
  customerNo: string;
  corporateAcctName?: string;
  duplicateCustomerName?: boolean;
  corporateAcctNo?: string;
  miLocCustomerNo?: string;
  effectiveDate?: string;
  expirationDate?: string;
}

export interface TrendData {
  date: number;
  busDate?: number;
  dateType?: string;
  show?: boolean;
  quantity?: number;
  dollars?: number;
  gpPercent?: number;
  gp?: number;
}

export interface ReportLineData {
  gpPercent: number;
  avgDaily: number;
  gp: number;
  transactions: number;
  sales: number;
}

export interface ReportsSummaryAPIResponse {
  currencyType?: string;
  requestType: DateSegmentType;
  cSalesMonth: string;
  cSalesYear: string;
  sales: SummaryItemInput[];
  profit: SummaryItemInput[];
  salesTrend?: SalesTrendExt[];
  profitTrend?: ProfitTrend[];
  datesWithSameBusDay?: CalendarDay[];
  pick12CustomerCount?: string;
  totalPick12CustCount?: string;
  pick12Pct?: string;
  webPerformance?: WebPerformanceOutput;
  previous?: ReportLineData;
}

export interface SummaryBillingItem {
  cost: number;
  gp: number;
  gpPercent: number;
  sales: number;
}

export interface EndOfMonthReport {
  currencyType: string;
  currentAvgDaily: number;
  currentAvgDailyChange: number;
  currentGp: number;
  currentGpChange: number;
  currentGpPercent: number;
  currentGpPercentChange: number;
  currentGpPlusSummaryBilling: number;
  currentGpPlusSummaryBillingChange: number;
  currentSales: number;
  currentSalesChange: number;
  currentSalesPlusSummaryBilling: number;
  currentSalesPlusSummaryBillingChange: number;
  previousAvgDaily: number;
  previousCost: number;
  previousGp: number;
  previousGpPercent: number;
  previousSales: number;
  summaryBilling: SummaryBillingItem;
}

export interface DrilldownTotals {
  sales: number;
  gpPercent: number;
  gp: number;
  activePicks: number;
  picks: number;
  activePickPercent: number;
  avgSalesPick: number;
  salesPrevious: number;
  salesYoyPercent: number;
  activeWebRegistered: number;
  webPercentOfTotalSales: number;
  avgDaily: number;
  overage: number;
  approvedSales: number;
  pendingSales: number;
  projectedSales: number;
  commitCurrentSales: number;
  approvedCount: number;
  disapprovedCount: number;
  disapprovedSales: number;
  pendingCount: number;
  maxApprovedSales: number;
  maxOverage: number;
}

export interface CostSavingTotals {
  approvedCount: number;
  approvedSales: number;
  commitCurrentSales: number;
  disapprovedCount: number;
  disapprovedSales: number;
  overage: number;
  pendingCount: number;
  pendingSales: number;
  projectedSales: number;
  sales: number;
}

export interface GetReportDrillDownAPIResponse {
  rows_count: number;
  rows: DrillDownItem[];
  totals: DrilldownTotals;
  totalRows: number;
  currencyType?: string;
}

export interface GetCSReportDrillDownAPIResponse {
  totalRows: number;
  rows: DrillDownItem[];
  totals: SummaryItemOutput[];
}

export interface GetReportSummaryResponse {
  sales: SummaryItemOutput[];
  profit: SummaryItemOutput[];
  salesTrend?: SalesTrend[];
  profitTrend?: ProfitTrend[];
  sameBusDay?: CalendarDay[];
  pick12CustomerCount?: string;
  totalPick12CustCount?: string;
  pick12Pct?: string;
  webPerformance?: WebPerformanceOutput;
  currencyType?: string;
  previous?: ReportLineData;
}

export interface ReportDrillDownItem {
  miLoc: string;
  id: string;
  Name: string;
  items: SummaryItemOutput[];
  customerPick12?: boolean;
  customerNo?: string;
  secondaryText?: string;
  disabledDrilldown?: boolean;
}

export interface ReportsURLParams {
  teamId?: string;
  miLoc?: string;
  id?: string;
  orgType?: ViewAsRoleType;
  tab?: string;
  pgc1?: string;
  year?: string;
}

export interface ReportsContextProps {
  busPeriod: number;
  requestType: DateSegmentType;
  sortField?: string;
  sortDir?: string;
  updateBusPeriod: (date: Date) => void;
  updateRequestType: (dateSegment?: DateSegmentType) => void;
  updateSortField?: (field: string) => void;
  updateSortDir?: (dir: string) => void;
}

export interface DrillDownLocation {
  miLoc: string;
  name: string;
}

export interface AccountRep {
  miLoc: string;
  id: string;
  name: string;
}

export interface ProductCategory {
  miLoc: string;
  pgc1: string;
  name: string;
}

export interface CalendarDay {
  gregorianDate: string;
  busDay: string;
  dateType: string;
  primary?: boolean;
}

export interface BusDayHashMap {
  [key: string]: CalendarDay;
}

export interface SalesTeamProps {
  miLoc: string;
  locName: string;
  team: boolean;
  userRole?: string;
}

export interface DataResponse {
  messages: MessageResponse[];
}
export interface MessageResponse {
  message: string;
  severity: string;
}
