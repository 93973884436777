import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { find, head, isEmpty, isNil, map, size, toString } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import CustomerName from 'common/components/Header/CustomerName';
import Header from 'common/components/Header/Header';
import List from 'common/components/List/List';
import { useIonContentRef } from 'common/components/utils/renderHelpers';
import useFindProducts from 'ProductSearchApp/api/useFindProducts';
import AdvancedSearchModal from 'ProductSearchApp/components/AdvancedSearchModal/AdvancedSearchModal';
import ProductListItem from 'ProductSearchApp/components/ProductListItem/ProductListItem';
import type { AdvancedSearchForm } from 'ProductSearchApp/models/Products';
import {
  productSearchByOptions,
  productSelectCheckboxOptions,
} from 'ProductSearchApp/util/productSearchUtil';
import { useDebounce } from 'use-debounce';
import useGetCustomer from 'api/customer/useGetCustomer';
import useShowCostDetailsDispatcher from 'hooks/useToggleCostDetailsDispatcher';
import type { SnapshotsURLParams } from 'models/Search';
import type { RootState } from 'store/reducers';
import { setProductSearchCustomer, clearCurrentCartCustomer } from 'store/user';
import { getErrorMessage } from 'utils/helpers';
import { findIcon } from 'utils/icons';
import { goToAddZCodedItem } from 'navigation/navigationHelpers';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import CheckBox from 'components/CheckBox/CheckBox';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Refresher from 'components/Refresher/Refresher';
import Searchbar from 'components/Searchbar/Searchbar';
import classes from './ProductSearch.module.scss';

const ProductSearch = (): JSX.Element => {
  const { miLoc, id } = useParams<SnapshotsURLParams>();
  const { data: customerData } = useGetCustomer({
    miLoc,
    id,
    searchType: 'customer',
  });
  const { nodeRef, node } = useIonContentRef();
  const dispatch = useDispatch();
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 1000);
  const [selectedOptions, setSelectedOptions] = useState({
    group1: 'corp',
    group2: '',
  });
  const [searchMode, setSearchMode] = useState(head(productSearchByOptions));
  const [triggerInputSelect, setTriggerInputSelect] = useState(0);
  const [isOpenSearchBy, setIsOpenSearchBy] = useState<boolean>(false);
  const [isOpenAdvancedSearch, serIsOpenAdvancedSearch] =
    useState<boolean>(false);
  const [advancedSearchFields, setAdvancesSearchFields] =
    useState<AdvancedSearchForm>();
  const { productSearchCustomer } = useSelector(
    (state: RootState) => state.user
  );
  const { t } = useTranslation();
  const history = useHistory();

  const zCodedNameSpace = 'productSearch:zCodedItem';
  const namespace = 'productSearch:ocn';
  const { toggleCostDetailsOption } = useShowCostDetailsDispatcher();
  const { showCostDetails } = useSelector((state: RootState) => state.user);
  const [diffCartCustomerIsOpen, setDiffCartCustomerIsOpen] = useState(false);

  const handleCheckboxChange = (option: string) => {
    setSelectedOptions((prevSelectedItems) => {
      if (option === 'branch' || option === 'corp') {
        return {
          ...prevSelectedItems,
          group1: prevSelectedItems.group1 === option ? '' : option,
        };
      }
      if (option === 'cust' || option === 'sales') {
        return {
          ...prevSelectedItems,
          group2: prevSelectedItems.group2 === option ? '' : option,
        };
      }
      return prevSelectedItems;
    });
  };

  useEffect(() => {
    dispatch(
      setProductSearchCustomer({
        productSearchCustomer: {
          miLoc,
          customerId: id,
          group1: selectedOptions.group1,
          group2: selectedOptions.group2,
          searchQuery: searchQueryValue,
          searchMode: searchMode?.id,
          advancedSearchFields,
        },
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    miLoc,
    id,
    searchQueryValue,
    selectedOptions.group1,
    selectedOptions.group2,
    searchMode?.id,
    advancedSearchFields,
  ]);

  const isCached =
    productSearchCustomer?.miLoc === miLoc &&
    productSearchCustomer.customerId === id;

  useEffect(() => {
    if (isCached) {
      setSearchQuery(toString(productSearchCustomer.searchQuery));
      setSelectedOptions({
        group1: toString(productSearchCustomer.group1),
        group2: toString(productSearchCustomer.group2),
      });
      const searchModeObj = find(
        productSearchByOptions,
        (item) => item.id === productSearchCustomer.searchMode
      );
      setSearchMode(searchModeObj);
      setAdvancesSearchFields(
        productSearchCustomer.advancedSearchFields as AdvancedSearchForm
      );
    } else {
      setSearchQuery('');
      setSelectedOptions({
        group1: 'corp',
        group2: '',
      });
      setSearchMode(head(productSearchByOptions));
      setAdvancesSearchFields(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  let enabled = !!searchQueryValue || !!advancedSearchFields?.mfrCtlNo?.key;

  if (
    isCached &&
    !isEmpty(searchQuery) &&
    !isEmpty(advancedSearchFields?.mfrCtlNo)
  ) {
    enabled = !!searchQueryValue && !!advancedSearchFields?.mfrCtlNo?.key;
  }

  const {
    products,
    totalRows,
    error,
    isLoading,
    isEmptyResponse,
    noMoreData,
    dataUpdatedAt,
    fetchNextPage,
    refetch,
  } = useFindProducts({
    miLoc,
    customerNo: id,
    query: searchQueryValue,
    inStockOnly: selectedOptions.group1 === 'corp',
    limitToBranch: selectedOptions.group1 === 'branch',
    limitToCustomer: selectedOptions.group2 === 'cust',
    branchItemBalance: selectedOptions.group2 === 'sales',
    searchMode: searchMode?.id,
    returnSCAvail: true,
    mfrCtlNo: advancedSearchFields?.mfrCtlNo?.key,
    enabled,
  });

  let title = t('productSearch:results', { size: totalRows });
  if (searchQueryValue) {
    title = t('productSearch:products', {
      size: totalRows,
      query: searchQueryValue,
    });
  }

  const { currentCartCustomer } = useSelector((state: RootState) => state.user);
  const addZCodedItem = () => {
    const differentCartCustomer =
      !isEmpty(currentCartCustomer) &&
      (currentCartCustomer?.miLoc !== customerData?.miLoc ||
        currentCartCustomer?.shipToCustNo !== customerData?.customerNo);

    if (differentCartCustomer) {
      setDiffCartCustomerIsOpen(true);
    } else {
      history.push(
        goToAddZCodedItem({
          miLoc,
          shipToCustNo: id,
          orderCtlNo: currentCartCustomer?.orderCtlNo,
          reset: true,
        })
      );
    }
  };

  const headerActions = {
    title: t(`${namespace}:manageOcn`),
    initialBreakpoint: 0.3,
    options: [
      {
        text: t(`${zCodedNameSpace}:addZCodedItem`),
        testid: 'add-zcoded-button',
        onClick: () => addZCodedItem(),
      },
      toggleCostDetailsOption(false),
    ],
  };

  return (
    <IonPage className={classes.container}>
      <Header
        customTitle={<CustomerName customerData={customerData} />}
        hideMenuButton
        hideLocationSelector={false}
        testid="product-search-header"
        headerActions={headerActions}
        className={classes.header}
      >
        <IonRow className={classes.searchBarWrapper}>
          <Button
            variant="action"
            text={head(searchMode?.text.split(' '))}
            testid="product-search-selection"
            className={classes.selectBtn}
            rightIcon={findIcon('chevron-down')}
            onClick={() => setIsOpenSearchBy(true)}
          />
          <Searchbar
            value={searchQuery}
            setValue={setSearchQuery}
            testid="product-search-bar"
            placeholder={t('productSearch:placeholder')}
            hideSearchIcon
            className={classes.searchBar}
            inputRowClassName={classes.inputRow}
            triggerInputSelect={triggerInputSelect}
          />
        </IonRow>
      </Header>
      <IonContent ref={nodeRef} className={classes.content}>
        <div className={classes.filterWrapper}>
          <div className={classes.checkboxWrapper}>
            {map(productSelectCheckboxOptions, (selectItem, index) => (
              <CheckBox
                key={`${selectItem}-${index}`}
                testid={`${selectItem}-${index}-checkbox`}
                name={selectItem}
                label={selectItem}
                checked={
                  selectItem === 'branch' || selectItem === 'corp'
                    ? selectedOptions.group1 === selectItem
                    : selectedOptions.group2 === selectItem
                }
                onChange={() => {
                  handleCheckboxChange(selectItem);
                }}
                labelTextVariant="mipro-body-copy"
                className={classes.checkbox}
              />
            ))}
          </div>
          <div className={classes.advancedSearchWrapper}>
            <Button
              testid="product-advanced-search"
              text={
                advancedSearchFields
                  ? toString(size(advancedSearchFields))
                  : t('common:filter')
              }
              leftIcon={findIcon('sliders')}
              className={classNames(classes.filterButton, {
                [classes.hasFilters]: !isNil(advancedSearchFields),
              })}
              variant="clear"
              onClick={() => {
                serIsOpenAdvancedSearch(true);
              }}
            />
          </div>
        </div>
        <Refresher
          slot="fixed"
          onRefresh={refetch}
          hidden
          testid="product-search-refresher"
          disabled={isLoading || !searchQueryValue || isEmptyResponse}
          lastUpdatedAt={dataUpdatedAt}
        />

        <List
          title={title}
          data={products}
          itemContent={(itemIndex, item) => (
            <ProductListItem
              productItem={item}
              key={`${item.itemNumber}-${itemIndex}`}
              miLoc={miLoc}
              customerId={id}
              testid={`${item.itemNumber}-${itemIndex}`}
              showGpPercent={showCostDetails}
            />
          )}
          scrollParent={node}
          isLoading={{
            isLoading,
            text: t('productSearch:loading'),
          }}
          isEmptyList={{
            isEmptyList: isEmptyResponse,
            title:
              searchQueryValue || advancedSearchFields?.mfrCtlNo?.key
                ? t('product:noProductsFound')
                : t('productSearch:noResults'),
            body: t('productSearch:noResultsMessage'),
            children: (
              <div className={classes.zCodeBtn}>
                <Button
                  text={t(`${zCodedNameSpace}:addZCodedItem`)}
                  testid="add-zcoded-item"
                  variant="mipro-action"
                  onClick={addZCodedItem}
                />
              </div>
            ),
          }}
          isError={{
            isError: !!error,
            title: getErrorMessage(error, t('productSearch:noResults')),
            body: t('productSearch:noResultsMessage'),
          }}
          isEndOfList={noMoreData}
          endReached={fetchNextPage}
          testid="products-list"
        />
        <SheetModal
          title={t('productSearch:searchBy')}
          isOpen={isOpenSearchBy}
          setIsOpen={setIsOpenSearchBy}
          initialBreakpoint={0.5}
          testid="product-search-by-modal"
          withRightCloseButton
        >
          <div className={classes.searchByContent}>
            {map(productSearchByOptions, (searchByItem, index) => {
              const selectedSearchBy = searchByItem.id === searchMode?.id;
              return (
                <ActionRow
                  testid={`${searchByItem.id}-${index}`}
                  key={`${searchByItem.id}-${index}`}
                  disabled={false}
                  onClick={() => {
                    setSearchMode(searchByItem);
                    setIsOpenSearchBy(false);
                    // Todo: Refractor to not use timeouts
                    setTimeout(() => {
                      setTriggerInputSelect(Date.now());
                    }, 1000);
                  }}
                >
                  {selectedSearchBy && (
                    <FontAwesomeIcon
                      icon={findIcon('check')}
                      className={classes.checkIcon}
                    />
                  )}
                  <div
                    className={classNames({ [classes.bold]: selectedSearchBy })}
                  >
                    {searchByItem.text}
                  </div>
                </ActionRow>
              );
            })}
          </div>
        </SheetModal>
        <AdvancedSearchModal
          isOpen={isOpenAdvancedSearch}
          setIsOpen={serIsOpenAdvancedSearch}
          testid="advanced-search-filter"
          setAdvancedSearch={(fields) => {
            setAdvancesSearchFields(fields);
          }}
          advancedSearchFields={advancedSearchFields}
        />
        <DiscardModal
          title={t('productSearch:ocn:differentOcnCustomerTitle', {
            customerName: customerData?.name,
          })}
          isOpen={diffCartCustomerIsOpen}
          setIsOpen={setDiffCartCustomerIsOpen}
          withRightCloseButton
          testid="different-cart-customer-modal"
          onGoBackClick={() => {
            dispatch(clearCurrentCartCustomer());
            setDiffCartCustomerIsOpen(false);
            history.push(
              goToAddZCodedItem({
                miLoc,
                shipToCustNo: id,
                reset: true,
              })
            );
          }}
          goBackButtonTitle={t(`${zCodedNameSpace}:createNewOCN`)}
          discardButtonTitle={t('common:cancel')}
          discardMsg={t(`${zCodedNameSpace}:differentCustomer`, {
            ocn: currentCartCustomer?.orderCtlNo,
            customerName: currentCartCustomer?.customerName,
            newCustomerName: customerData?.name,
          })}
        />
      </IonContent>
    </IonPage>
  );
};

export default ProductSearch;
