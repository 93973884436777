import React from 'react';
import { find } from 'lodash';
import {
  ShopQuoteColorEnum,
  ShopQuoteFilterEnum,
  ShopQuoteIconEnum,
  ShopQuoteTypeEnum,
} from 'ActivitiesApp/models/ShopQuote';
import { choose } from 'common/utils/logicHelpers';
import type {
  ActionCardConfig,
  ActivityTypeConfig,
  GetActivityConfigExtendedProps,
  GetActivityTypeDataProps,
} from 'pages/Activities/ActivityActionCard/ActivityCardConfig';

export const getShopQuoteData = ({
  eventTagName,
  t,
}: GetActivityTypeDataProps): ActivityTypeConfig | undefined => {
  if (eventTagName === ShopQuoteTypeEnum.shopQuote) {
    return {
      cardType: ShopQuoteTypeEnum.shopQuote,
      title: t('shopQuoteReady'),
      icon: ShopQuoteIconEnum.shopQuote,
      color: ShopQuoteColorEnum.shopQuote,
    };
  }
  return undefined;
};

export const getShopQuoteConfig = ({
  activityVersion,
  activityData,
  defaultFirstLine,
  t,
}: GetActivityConfigExtendedProps): ActionCardConfig | undefined => {
  const data = activityData;
  const item = find(data?.fields, { name: 'Item Description' })?.value;
  const location = find(data?.fields, { name: 'MI Loc' })?.value;
  const shop = find(data?.fields, { name: 'Work Order Miloc Name' })?.value;

  if (activityVersion === `${ShopQuoteTypeEnum.shopQuote}-1`) {
    return {
      card: {
        defaultTitle: t('shopQuoteReady'),
        highlight: [{ query: t('shopQuoteReady') }],
        body: {
          description: '',
          lines: [
            ...defaultFirstLine,
            {
              description: choose(
                item && location && shop,
                t('shopQuoteDescription', {
                  item,
                  location,
                  shop,
                }),
                t('shopQuoteDescriptionFallback')
              ) as string,
            },
          ],
        },
      },
      hasComments: false,
      modal: {
        title: t('activities:shopQuoteReady'),
        hideSaveButton: true,
        customContent: () => <></>,
      },
    };
  }
  return undefined;
};

export const getShopQuoteFilterIcon = (
  type: string
): ShopQuoteIconEnum | undefined => {
  if (type === ShopQuoteFilterEnum.shopQuote) {
    return ShopQuoteIconEnum.shopQuote;
  }
  return undefined;
};
