import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';
import classNames from 'classnames';
import {
  find,
  findIndex,
  head,
  isEmpty,
  map,
  size,
  toNumber,
  toString,
} from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IonContent,
  IonGrid,
  IonPage,
  IonRow,
  IonCol,
  IonLoading,
  IonFooter,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
} from '@ionic/react';
import Alert from 'common/components/Alert/Alert';
import Header from 'common/components/Header/Header';
import { or } from 'common/utils/logicHelpers';
import { emptyString } from 'common/utils/valueFormatter';
import useAddZeroCounts from 'InventoryApp/api/useAddZeroCounts';
import useFindCountGroupItems from 'InventoryApp/api/useFindCountGroupItems';
import useGetCountGroup from 'InventoryApp/api/useGetCountGroup';
import useRemoveCountGroup from 'InventoryApp/api/useRemoveCountGroup';
import useSubmitInventoryOrder from 'InventoryApp/api/useSubmitInventoryOrder';
import useUpdateCountGroup from 'InventoryApp/api/useUpdateCountGroup';
import useUpdateItemCount from 'InventoryApp/api/useUpdateItemCount';
import AddItemModal from 'InventoryApp/components/AddItemModal/AddItemModal';
import InventoryCustomTitle from 'InventoryApp/components/InventoryCustomTitle/InventoryCustomTitle';
import OrderModal from 'InventoryApp/components/OrderModal/OrderModal';
import Scanner from 'InventoryApp/components/Scanner/Scanner';
import useInventoryHeaderActions from 'InventoryApp/hooks/useInventoryHeaderActions';
import type {
  CountGroup,
  CountGroupItem,
  CreatePlanAndGroupsURLParams,
} from 'InventoryApp/models/InventoryPlanGroup';
import { isStartVMI, isPiBelting } from 'InventoryApp/util/inventoryUtil';
import { homeInventoryURL, inventoryURL } from 'navigation';
import { useNetworkStatus } from 'providers/NetworkStatusProvider';
import { useToasts } from 'providers/ToastProvider';
import { useDebounce } from 'use-debounce';
import useGetCustomer from 'api/customer/useGetCustomer';
import useGoBack from 'hooks/useGoBack';
import { SortDirEnum } from 'models/Sort';
import { ToastType } from 'models/Toast';
import { findIcon } from 'utils/icons';
import { concatRoutes } from 'utils/navigations';
import { removeLeadingZeros } from 'utils/number';
import ActionRow from 'components/ActionRow/ActionRow';
import Button from 'components/Button/Button';
import type { FilterOption } from 'components/Filter/Filter';
import Filter from 'components/Filter/Filter';
import Loader from 'components/Loader/Loader';
import ConfirmDialog from 'components/Modals/ConfirmDialog/ConfirmDialog';
import DiscardModal from 'components/Modals/DiscardModal/DiscardModal';
import SheetModal from 'components/Modals/SheetModal/SheetModal';
import Searchbar from 'components/Searchbar/Searchbar';
import SegmentTabs from 'components/SegmentTabs/SegmentTabs';
import Text from 'components/Text/Text';
import WarningMessage from 'components/WarningMessage/WarningMessage';
import classes from './CountPlanInventory.module.scss';

enum TabType {
  ALL = 'ALL',
  COUNTED = 'COUNTED',
}

enum ItemSortTypeEnum {
  'PAGE SORT' = 'PAGE SORT',
  'VENDOR' = 'VENDOR',
  'FULL MINO' = 'FULL MINO',
  'MFG ID DESCRIPTION' = 'MFG ID DESCRIPTION',
  'BIN LOCATION' = 'BIN LOCATION',
  'LOT NO' = 'LOT NO',
  'CUSTOMER STOCK NO' = 'CUSTOMER STOCK NO',
  'ITEM NO' = 'ITEM NO',
  'MINO' = 'MINO',
}

interface ListItemProps {
  item: CountGroupItem;
  selectedTab?: string;
  searchQuery?: string;
  disabled?: boolean;
  triggerCloseItem?: number;
  onClear?: () => void;
  onSelect?: () => void;
  countGroup?: CountGroup;
}

const ListItem = ({
  item,
  selectedTab = '',
  searchQuery,
  disabled,
  triggerCloseItem,
  onClear,
  onSelect,
  countGroup,
}: ListItemProps): JSX.Element => {
  const { t } = useTranslation();
  const itemRef = useRef<HTMLIonItemSlidingElement>(null);
  const allItemsTab = selectedTab === TabType.ALL;

  useEffect(() => {
    if (toNumber(triggerCloseItem) > 0) {
      void itemRef.current?.closeOpened();
    }
  }, [triggerCloseItem]);

  return (
    <IonItemSliding ref={itemRef} disabled={disabled}>
      {item.hasCount && (
        <IonItemOptions>
          <IonItemOption className={classes.options} onClick={onClear}>
            <FontAwesomeIcon icon={findIcon('eraser', 'far')} />
            <Text variant="mipro-body-copy" text={t('inventory:clear')} />
          </IonItemOption>
        </IonItemOptions>
      )}
      <ActionRow
        className={classNames(classes.item, {
          [classes.selectedItem]: allItemsTab && item.hasCount,
          [classes.deletedItem]: disabled || item.isOptimisticallyUpdating,
        })}
        withoutHrefArrow={!item?.hasCount && !disabled && !item.hasEditedMinMax}
        onClick={() => {
          if (!disabled) {
            onSelect?.();
          }
        }}
        testid={`item-${item.uniqueId}`}
        // TODO: key items like this would fix item reference bugs in other places
        key={`${selectedTab}-${item.uniqueId}`}
      >
        <IonGrid>
          <IonRow
            className={classNames(classes.itemText, classes.itemTitle, {
              [classes.firstRow]: toNumber(item.onOrderQty) === 0,
            })}
          >
            <Text
              variant="mipro-product-headline"
              className={classes.itemName}
              text={item.description || '--'}
              textQuery={searchQuery}
            />
            <div className={classes.itemIcons}>
              {item.type === 'M' && !item.itemNo && (
                <div className={classes.itemCheckCount}>
                  <FontAwesomeIcon
                    className={classNames({
                      [classes.itemDisabledIcon]: disabled,
                      [classes.itemMemoIcon]: !disabled,
                    })}
                    icon={findIcon('memo', 'fas')}
                  />
                </div>
              )}
              {item.hasEditedMinMax && (
                <div className={classes.itemCheckCount}>
                  <div
                    className={classNames(classes.itemCircle, {
                      [classes.itemDisabledIcon]: disabled,
                      [classes.itemSliderIcon]: !disabled,
                    })}
                  >
                    <FontAwesomeIcon icon={findIcon('sliders-simple', 'fas')} />
                  </div>
                </div>
              )}
              {item.hasCount && (
                <div className={classes.itemCheckCount}>
                  <div
                    className={classNames(classes.itemCircle, {
                      [classes.itemDisabledIcon]: disabled,
                      [classes.itemCheckIcon]: !disabled,
                    })}
                  >
                    <FontAwesomeIcon icon={findIcon('check', 'fas')} />
                  </div>
                  <Text
                    variant="mipro-product-headline"
                    text={toString(item.actualCount)}
                  />
                </div>
              )}
            </div>
          </IonRow>
          {toNumber(item.onOrderQty) !== 0 && (
            <IonRow className={classNames(classes.itemText, classes.firstRow)}>
              <Text
                className={classes.onOrderQty}
                variant="mipro-report-info"
                text={t('inventory:onOrderQty', {
                  onOrderQty: item.onOrderQty,
                })}
                textQuery={searchQuery}
              />
            </IonRow>
          )}
          <IonRow className={classes.itemText}>
            <Text
              className={classes.text}
              variant="mipro-report-info"
              text={t('inventory:bin', { bin: item.bin || '--' })}
              textQuery={searchQuery}
            />
            <Text
              className={classes.text}
              variant="mipro-report-info"
              text={t('inventory:mino', {
                mino: or(item.mino, emptyString),
              })}
              textQuery={searchQuery}
            />
          </IonRow>
          <IonRow className={classes.itemText}>
            <Text
              className={classes.text}
              variant="mipro-report-info"
              text={t('inventory:itemNo', {
                itemNo: removeLeadingZeros(item.itemNo) || '--',
              })}
              textQuery={searchQuery}
            />
            <Text
              className={classes.text}
              variant="mipro-report-info"
              text={t('inventory:uom', { uom: item.uom || '--' })}
            />
          </IonRow>
          {(isStartVMI(countGroup) || countGroup?.storeroomNo !== '000') && (
            <IonRow className={classes.itemText}>
              <Text
                className={classes.text}
                variant="mipro-report-info"
                text={t('inventory:csn', {
                  csn: item.custStockNo || '--',
                })}
                textQuery={searchQuery}
              />
            </IonRow>
          )}
          {!item.allowEdit && (
            <Alert
              text={t('inventory:updateWarning')}
              className={classes.warningText}
            />
          )}
        </IonGrid>
      </ActionRow>
    </IonItemSliding>
  );
};

const CountPlanInventory = (): JSX.Element => {
  const { countPlanId, groupId, groupUniqueId } =
    useParams<CreatePlanAndGroupsURLParams>();
  const { t } = useTranslation();
  const history = useHistory();
  const { isOnline } = useNetworkStatus();
  const { addToast } = useToasts();
  const { goBack } = useGoBack();

  const pageRef = useRef<HTMLIonContentElement>(null);

  const [selectedItem, setSelectedItem] = useState<CountGroupItem>();
  const [openOrderModal, setOpenOrderModal] = useState(false);
  const [discardModalIsOpen, setDiscardModalIsOpen] = useState(false);
  const [isSubmitConfirm, setIsSubmitConfirm] = useState(false);
  const [isCompleteCountConfirm, setIsCompleteCountConfirm] = useState(false);
  const [countZeroOpen, setCountZeroOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState<TabType>(TabType.ALL);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchQueryValue] = useDebounce(searchQuery, 300);
  const [showConfirmationModal, setConfirmationModal] = useState(false);
  const [showSortModal, setShowSortModal] = useState(false);
  const [triggerScan, setTriggerScan] = useState(0);
  const [openAddItemModal, setOpenAddItemModal] = useState(false);
  const [triggerCloseItem, setTriggerCloseItem] = useState(0);
  const [triggerNextItem, setTriggerNextItem] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [triggerSwipeLeft, setTriggerSwipeLeft] = useState(0);
  const [triggerSwipeRight, setTriggerSwipeRight] = useState(0);

  const {
    countGroup,
    isLoading: countGroupLoading,
    countPlanOptions,
  } = useGetCountGroup({ countPlanId, groupId, groupUniqueId });

  const { onUpdateCountGroup } = useUpdateCountGroup({ countPlanId, groupId });
  const { onAddZeroCounts } = useAddZeroCounts({
    groupUniqueId: toString(countGroup?.uniqueId),
  });

  const { data: customerData, isLoading: customerLoading } = useGetCustomer({
    miLoc: toString(countGroup?.miLoc),
    id: toString(countGroup?.customerNo),
    searchType: 'customer',
  });

  const isPIBelting = isPiBelting(countGroup);

  const getSortFieldData = (key = '') => {
    switch (key) {
      case ItemSortTypeEnum.VENDOR:
        return {
          key,
          name: t('inventory:sortVendor'),
          field: 'mfrName',
        };
      case ItemSortTypeEnum['MFG ID DESCRIPTION']:
        return { key, name: t('inventory:sortMfrName'), field: 'description' };
      case ItemSortTypeEnum['BIN LOCATION']:
        return { key, name: t('inventory:sortBin'), field: 'bin' };
      case ItemSortTypeEnum['LOT NO']:
        return {
          key,
          name: isPIBelting ? t('inventory:sortSlab') : t('inventory:sortLot'),
          field: 'lotNo',
        };
      case ItemSortTypeEnum['CUSTOMER STOCK NO']:
        return { key, name: t('inventory:sortCSN'), field: 'custStockNo' };
      case ItemSortTypeEnum['ITEM NO']:
        return { key, name: t('inventory:sortItemNo'), field: 'itemNo' };
      case ItemSortTypeEnum.MINO:
        return { key, name: t('inventory:sortMino'), field: 'groupSerialNo' };
      case ItemSortTypeEnum['FULL MINO']:
        return { key, name: t('inventory:sortFullMino'), field: 'mino' };
      case ItemSortTypeEnum['PAGE SORT']:
      default:
        return { key, name: t('inventory:sortPage'), field: 'pageNo, lineNo' };
    }
  };

  const getSortDirData = (key: string) => {
    switch (key) {
      case SortDirEnum.DESCENDING:
        return 'DESC';
      case SortDirEnum.ASCENDING:
      default:
        return 'ASC';
    }
  };

  const defaultSortField =
    countGroup?.localSortField ||
    countGroup?.sortSequenceName ||
    ItemSortTypeEnum['PAGE SORT'];
  const defaultSortDir = countGroup?.localSortDir || SortDirEnum.ASCENDING;
  const [sortField, setSortField] = useState(defaultSortField);
  const [sortDir, setSortDir] = useState(defaultSortDir);
  const [updateGroupSort, setUpdateGroupSort] = useState(false);

  useEffect(() => {
    if (countGroup?.uniqueId) {
      setSortField(defaultSortField);
      setSortDir(defaultSortDir);
      setUpdateGroupSort(true);
    }
  }, [countGroup?.uniqueId, defaultSortDir, defaultSortField]);

  useEffect(() => {
    if (
      countGroup?.uniqueId &&
      updateGroupSort &&
      (sortField !== defaultSortField || sortDir !== defaultSortDir)
    ) {
      onUpdateCountGroup({
        groupUniqueId: countGroup.uniqueId,
        sortField,
        sortDir,
        miLoc: countGroup.miLoc,
        customerNo: countGroup.customerNo,
      });
      setUpdateGroupSort(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countGroup?.uniqueId, sortDir, sortField]);

  const sortFieldOptions: FilterOption[] = map(
    Object.keys(ItemSortTypeEnum),
    (key) => getSortFieldData(key)
  );
  const sortDirOptions: FilterOption[] = [
    {
      key: SortDirEnum.ASCENDING,
      name: t('sort:alphaAscending'),
      icon: 'arrow-up',
    },
    {
      key: SortDirEnum.DESCENDING,
      name: t('sort:alphaDescending'),
      icon: 'arrow-down',
    },
  ];

  const selectedFilters = useMemo(
    () => [
      find(sortFieldOptions, { key: sortField }) || {
        key: ItemSortTypeEnum['PAGE SORT'],
        name: getSortFieldData().name,
      },
      find(sortDirOptions, { key: sortDir }),
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sortField, sortDir]
  );

  const {
    items,
    total: itemsTotal,
    showLoader: itemsLoading,
    isEmptyResponse: itemsEmptyResponse,
  } = useFindCountGroupItems({
    countPlanId,
    groupId: toString(countGroup?.uniqueId),
    query: searchQueryValue,
    sortField: getSortFieldData(sortField).field,
    sortDir: getSortDirData(sortDir),
  });

  const {
    items: countedItems,
    total: countedTotal,
    showLoader: countedItemsLoading,
    isEmptyResponse: countedEmptyResponse,
    findBarcodeItems,
  } = useFindCountGroupItems({
    countPlanId,
    groupId: toString(countGroup?.uniqueId),
    query: searchQueryValue,
    hasCount: true,
    sortField: getSortFieldData(sortField).field,
    sortDir: getSortDirData(sortDir),
  });

  const emptyResponse =
    (selectedTab === TabType.ALL && itemsEmptyResponse) ||
    (selectedTab === TabType.COUNTED && countedEmptyResponse);

  const { status, onUpdateItemCount } = useUpdateItemCount();
  const { status: submitOrderStatus, onSubmitInventoryOrder } =
    useSubmitInventoryOrder();
  const { onRemoveCountGroup, status: deleteStatus } = useRemoveCountGroup();

  const isDeleting = deleteStatus === 'loading';
  const isSubmitting = submitOrderStatus === 'loading';

  const isCreateOrder = countPlanOptions?.createOrder === 'Y';

  const allItemsCounted = countedTotal === itemsTotal;

  useEffect(() => {
    if (deleteStatus === 'success') {
      goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteStatus]);

  useEffect(() => {
    if (
      countGroup?.uniqueId &&
      !countGroup?.billCustomerName &&
      customerData?.customerNo
    ) {
      onUpdateCountGroup({
        groupUniqueId: countGroup.uniqueId,
        billCustomerNo: customerData?.customerNo,
        billCustomerName: customerData?.name,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    countGroup?.billCustomerName,
    countGroup?.uniqueId,
    customerData?.customerNo,
    customerData?.name,
  ]);

  useEffect(() => {
    if (submitOrderStatus === 'success' && countPlanId && groupId) {
      history.replace(concatRoutes(inventoryURL(), homeInventoryURL()));
    }
  }, [countPlanId, groupId, history, submitOrderStatus]);

  const listItems = selectedTab === TabType.ALL ? items : countedItems;

  const goToNextItem = () => {
    if (currentIndex === size(listItems) - 1 || currentIndex === -1) {
      setOpenOrderModal(false);
    } else {
      setSelectedItem(listItems?.[currentIndex + 1]);
      setCurrentIndex(currentIndex + 1);
    }
  };

  const goToPrevItem = () => {
    if (currentIndex === 0 || currentIndex === -1) {
      setOpenOrderModal(false);
    } else {
      setSelectedItem(listItems?.[currentIndex - 1]);
      setCurrentIndex(currentIndex - 1);
    }
  };

  useEffect(() => {
    if (triggerSwipeLeft > 0) {
      setTriggerSwipeLeft(0);
      goToNextItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSwipeLeft]);

  useEffect(() => {
    if (triggerSwipeRight > 0) {
      setTriggerSwipeRight(0);
      goToPrevItem();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerSwipeRight]);

  useEffect(() => {
    if (status === 'success') {
      if (triggerSwipeRight < 0) {
        setTriggerSwipeRight(0);
        goToPrevItem();
      } else if (triggerSwipeLeft < 0) {
        setTriggerSwipeLeft(0);
        goToNextItem();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    if (triggerNextItem > 0 && listItems) {
      setTimeout(() => {
        setTriggerNextItem(0);
        goToNextItem();
      }, 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listItems, triggerNextItem]);

  const showLoader =
    countGroupLoading || customerLoading || itemsLoading || countedItemsLoading;
  const isOrderModel = countGroup?.model === 'ORDER' || isPiBelting(countGroup);

  const doSearch = (query: string) => {
    if (!isEmpty(query)) {
      return;
    }
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();
    }
  };

  const onSearch = (query: string) => {
    setSearchQuery(query);
    setTimeout(() => {
      void doSearch(query);
    }, 300);
  };

  const calculateOrderQuantity = (
    quantity: number,
    minValue?: number,
    maxValue?: number
  ): number => {
    let orderQuantity = toNumber(quantity);
    if (!isOrderModel) {
      // if BOH <= min then place order to bring up to max; otherwise no order
      orderQuantity = 0;
      if (quantity <= toNumber(minValue)) {
        orderQuantity = toNumber(maxValue) - quantity;
      }
      orderQuantity = orderQuantity > 0 ? orderQuantity : 0;
    }
    return orderQuantity;
  };

  const handleOrderQuantity = (
    itemId: string,
    quantity: number,
    width?: number,
    length?: number,
    minValue?: number,
    maxValue?: number
  ) => {
    const orderQuantity = calculateOrderQuantity(quantity, minValue, maxValue);
    onUpdateItemCount({
      itemId,
      groupUniqueId: toString(countGroup?.uniqueId),
      actualCount: toString(quantity || 0),
      orderQuantity,
      width,
      length,
    });
  };

  const disabledActions =
    countedTotal === 0 || countGroup?.isDeleted || isSubmitting;
  const disableItems = countGroup?.isDeleted || isSubmitting;

  const tabs = [
    {
      key: TabType.ALL,
      text: t('inventory:allItems', { count: itemsTotal }),
      buttonText: t('inventory:review'),
    },
    {
      key: TabType.COUNTED,
      text: t('inventory:counted', { count: countedTotal }),
      buttonText: isCreateOrder
        ? t('inventory:submitOrder')
        : t('inventory:submitCount'),
    },
  ];

  const handleSubmitInventoryOrder = (
    keepLocal: boolean,
    completeGroup = false
  ) => {
    onSearch('');
    if (countGroup && countedItems) {
      const { miLoc, storeroomNo, amtPctDiff, qtyPctDiff, countType } =
        countGroup;

      onSubmitInventoryOrder({
        miLoc,
        countPlanId,
        groupId: toNumber(groupId),
        groupUniqueId: toString(countGroup?.uniqueId),
        storeroomNo,
        amtPctDiff,
        qtyPctDiff,
        createOrder: isCreateOrder,
        unassignGroup: !keepLocal,
        countType,
        completeGroup,
        keepLocal,
      });
    }
  };

  const { headerActions } = useInventoryHeaderActions({
    customOptions: [
      {
        testid: 'sort-items',
        text: t('inventory:sortItems'),
        onClick: () => {
          setShowSortModal(true);
        },
        children: (
          <div className={classes.sortBadge}>
            {map(
              selectedFilters,
              (i) =>
                !!i && (
                  <div key={i.key} className={classes.sortLabel}>
                    {i.icon && <FontAwesomeIcon icon={i.icon} />}
                    <Text text={i.name} />
                  </div>
                )
            )}
          </div>
        ),
      },
    ],
    withAddItem:
      isOnline &&
      countPlanOptions?.hasManualSheets === 'Y' &&
      !countGroup?.isDeleted &&
      toNumber(countGroup?.downloadedItems) > 0,
    onAddItem: () => setOpenAddItemModal(true),
    withAddCountZero: countPlanOptions?.forceCountAllItems === 'Y',
    onAddCountZero: () => setCountZeroOpen(true),
    withDeleteCount: true,
    onDeleteGroupCount: () => {
      if (isOnline) {
        setConfirmationModal(true);
        return;
      }
      addToast({
        type: ToastType.alert,
        variant: 'mipro-toast',
        text: t('inventory:offlineDeleteMsg'),
        testid: 'count-group-delete-warn-toast',
      });
    },
  });

  let title = countGroup?.billCustomerName;
  title ||= customerData?.name;
  title ||= countGroup?.planName;

  return (
    <IonPage test-id="count-group-items-page" ref={pageRef}>
      <Header
        testid="count-group-items"
        hideCartButton
        hideMenuButton
        backButton={{
          className: classes.backButton,
        }}
        customTitle={
          <InventoryCustomTitle title={title} subTitle={countGroup?.name} />
        }
        headerActions={{ ...headerActions, className: classes.headerAction }}
        className={classes.header}
      >
        <SegmentTabs
          className={classes.countPlanTabs}
          selectedClassName={classes.selectedTab}
          options={tabs}
          value={selectedTab}
          setValue={(v) => setSelectedTab(v as TabType)}
          testid="count-plan-tabs"
        />
        {!!countGroup?.isDeleted && (
          <Alert
            text={t('inventory:countGroupNotExist')}
            className={classes.isDeletedLabel}
          />
        )}
        <IonRow className={classes.searchRow}>
          <Searchbar
            disabled={isSubmitting}
            className={classes.searchBar}
            inputClassName={classes.searchInput}
            value={searchQuery}
            placeholder={t('inventory:searchPlaceholder')}
            setValue={setSearchQuery}
            onSearch={() => onSearch(searchQuery)}
            onClear={() => setSearchQuery('')}
            variant="light"
            testid="search-input"
          />
          {!countGroup?.isDeleted && (
            <Scanner
              pageRef={pageRef}
              className={classes.scanButton}
              testid="products-scanner"
              onScan={async (result) => {
                return head(await findBarcodeItems?.(result?.content))
                  ?.description;
              }}
              onDone={async (result) => {
                const item = head(await findBarcodeItems?.(result?.content));
                if (item) {
                  const index = findIndex(listItems, {
                    uniqueId: item.uniqueId,
                  });
                  setCurrentIndex(index);
                  setSelectedItem(item);
                  setOpenOrderModal(true);
                }
              }}
              triggerScan={triggerScan}
              showCameraAcessMsg
            />
          )}
        </IonRow>
      </Header>
      <IonContent scrollY={false}>
        <Filter
          className={classes.sortModal}
          variant="sort"
          initialBreakpoint={0.6}
          selectedItems={selectedFilters}
          customContent={
            <Button
              variant="link"
              className={classes.defaultSortButton}
              text={t('inventory:defaultSort', {
                field: getSortFieldData(countGroup?.sortSequenceName).name,
              })}
              onClick={() => {
                setSortField?.(
                  countGroup?.sortSequenceName || ItemSortTypeEnum['PAGE SORT']
                );
                setSortDir?.(SortDirEnum.ASCENDING);
                setShowSortModal(false);
              }}
              testid="default-sort-button"
            />
          }
          setFilterData={[
            (option) => setSortField?.(toString(option?.key)),
            (option) => setSortDir?.(toString(option?.key)),
          ]}
          filterOptions={[
            {
              title: t('common:sortBy'),
              options: sortFieldOptions,
            },
            {
              title: t('common:sortOrder'),
              options: sortDirOptions,
            },
          ]}
          isOpen={showSortModal}
          setIsOpen={setShowSortModal}
          testid="report-sorter"
        />
        <IonLoading
          isOpen={isDeleting}
          message={t('inventory:deleteCountGroupOfflineMsg')}
        />
        <IonLoading
          isOpen={isSubmitting}
          message={
            isCreateOrder
              ? t('inventory:submitOrderLoading')
              : t('inventory:submitCountGroupLoading')
          }
        />
        {itemsEmptyResponse &&
          !showLoader &&
          !searchQueryValue &&
          selectedTab === TabType.ALL && (
            <WarningMessage
              icon={['far', 'info-circle']}
              title={t('inventory:countItemsEmptyMsg')}
              className={classes.warningMessage}
            />
          )}
        {searchQueryValue && !showLoader && emptyResponse && (
          <WarningMessage
            className={classes.warningMessage}
            title={t('inventory:emptySearchTitle')}
            body={t('inventory:emptySearchBody')}
          />
        )}
        {!searchQueryValue &&
          !showLoader &&
          selectedTab === TabType.COUNTED &&
          isEmpty(countedItems) && (
            <WarningMessage
              className={classes.warningMessage}
              title={t('inventory:emptyCountedTitle')}
              body={t('inventory:emptyCountedBody')}
            />
          )}
        <Virtuoso
          data={listItems}
          increaseViewportBy={{ top: 500, bottom: 500 }}
          components={{
            Footer: () => (
              <>
                {showLoader && (
                  <Loader
                    testid="loader"
                    text={t('common:loading')}
                    className={classes.loader}
                    isOpen={showLoader}
                  />
                )}
              </>
            ),
          }}
          itemContent={(index, item) => (
            <ListItem
              key={item.uniqueId}
              countGroup={countGroup}
              item={item}
              selectedTab={selectedTab}
              searchQuery={searchQuery}
              disabled={disableItems || !item.allowEdit}
              triggerCloseItem={triggerCloseItem}
              onClear={() => {
                setSelectedItem(item);
                setDiscardModalIsOpen(true);
              }}
              onSelect={() => {
                setSelectedItem(item);
                setOpenOrderModal(true);
                setCurrentIndex(index);
              }}
            />
          )}
        />
        <OrderModal
          calculateOrderQuantity={calculateOrderQuantity}
          handleOrderQuantity={handleOrderQuantity}
          setTriggerScan={setTriggerScan}
          item={selectedItem}
          setTriggerSwipeLeft={setTriggerSwipeLeft}
          setTriggerSwipeRight={setTriggerSwipeRight}
          setIsOpen={setOpenOrderModal}
          testid="order-modal"
          isOpen={openOrderModal}
          isOrderModel={isOrderModel}
          setTriggerNextItem={setTriggerNextItem}
          itemCount={items?.length || 0}
          countedItemCount={countedItems?.length || 0}
          isFiltered={searchQuery > ''}
          countGroup={countGroup}
        />
        <AddItemModal
          setIsOpen={setOpenAddItemModal}
          isOpen={openAddItemModal}
          testid="add-item"
          countGroup={countGroup}
        />
        <SheetModal
          isOpen={showConfirmationModal}
          setIsOpen={setConfirmationModal}
          testid="delete-count-group-modal"
          title={t('inventory:deleteGroupCountTitle')}
          initialBreakpoint={0.4}
          backdropDismiss={false}
          withRightCloseButton
          className={classes.confirmModal}
          contentClass={classes.modalContent}
        >
          <IonRow>
            <IonCol size="12">
              <Text
                text={t('inventory:deleteGroupCountMessage')}
                variant="mipro-body-copy"
              />
            </IonCol>
          </IonRow>
          <IonRow className={classes.btnWrapper}>
            <IonCol size="12">
              <Button
                variant="action"
                text={t('inventory:goBack')}
                testid="count-group-go-back-button"
                className={classes.fullWidth}
                onClick={() => setConfirmationModal(false)}
              />
            </IonCol>
            <IonCol size="12">
              <Button
                variant="mipro-danger-default"
                text={t('inventory:deleteGroup')}
                testid="count-group-delete-button"
                className={classes.fullWidth}
                onClick={() => {
                  onRemoveCountGroup({
                    name: toString(countGroup?.customerName),
                    miLoc: toString(countGroup?.miLoc),
                    groupUniqueId: toString(countGroup?.uniqueId),
                    countPlanId,
                    isDeleted: countGroup?.isDeleted,
                    deleteGroupOnServer: isStartVMI(countGroup),
                    groupId: toString(countGroup?.groupId),
                  });
                  setConfirmationModal(false);
                }}
              />
            </IonCol>
          </IonRow>
        </SheetModal>
        <DiscardModal
          title={t('inventory:deleteItemCountTitle')}
          className={classes.discardModal}
          isOpen={discardModalIsOpen}
          setIsOpen={setDiscardModalIsOpen}
          initialBreakpoint={0.4}
          backdropDismiss={false}
          withRightCloseButton
          testid="discard-changes-modal"
          goBackButtonTitle={t('inventory:goBack')}
          discardButtonTitle={t('inventory:deleteItemCount')}
          onDiscardClick={() => {
            if (selectedItem) {
              onUpdateItemCount({
                itemId: selectedItem.uniqueId,
                groupUniqueId: toString(countGroup?.uniqueId),
                actualCount: '',
                orderQuantity: 0,
                hasCount: false,
                hasLocalCount: false,
              });
            }
            setTriggerCloseItem(Date.now());
          }}
          secondayButtonVariant="mipro-danger-default"
        />
        <ConfirmDialog
          isOpen={isCompleteCountConfirm && !isStartVMI(countGroup)}
          setIsOpen={setIsCompleteCountConfirm}
          title={t('inventory:completeCountGroup')}
          text={t('inventory:completeCountGroupText')}
          primaryText={t('common:yes')}
          secondaryText={t('common:no')}
          onPrimaryClick={() => handleSubmitInventoryOrder(false, true)}
          onSecondaryClick={() => handleSubmitInventoryOrder(false)}
          testid="submit-order-confirm-completed-modal"
        />
        <ConfirmDialog
          isOpen={isSubmitConfirm}
          setIsOpen={setIsSubmitConfirm}
          title={t('inventory:submitOrderConfirmTitle')}
          text={t('inventory:submitOrderConfirmMsg')}
          primaryText={t('common:yes')}
          secondaryText={t('common:no')}
          onPrimaryClick={() => handleSubmitInventoryOrder(true)}
          onSecondaryClick={() => handleSubmitInventoryOrder(false)}
          testid="submit-order-confirm-modal"
        />
        <ConfirmDialog
          isOpen={countZeroOpen}
          setIsOpen={setCountZeroOpen}
          title={t('inventory:addZeroCount')}
          text={t('inventory:addZeroCountMessage')}
          primaryText={t('common:yes')}
          secondaryText={t('common:no')}
          onPrimaryClick={() => onAddZeroCounts()}
          testid="add-count-zero-confirm-modal"
        />
      </IonContent>
      {!countGroup?.isDeleted && (
        <IonFooter className={classes.footer}>
          <div className={classes.footerButtons}>
            <Button
              className={classNames(classes.reviewButton, {
                [classes.disabledReview]: disabledActions,
              })}
              variant="action"
              onClick={() => {
                if (selectedTab === TabType.ALL) {
                  setSelectedTab(TabType.COUNTED);
                  return;
                }
                if (!isOnline) {
                  addToast({
                    type: ToastType.alert,
                    variant: 'mipro-toast',
                    text: t('inventory:offlineSubmitOrder'),
                    testid: 'submit-order-offline',
                  });
                  return;
                }
                if (!isCreateOrder) {
                  if (allItemsCounted) {
                    setIsCompleteCountConfirm(true);
                  } else {
                    setIsSubmitConfirm(true);
                  }
                } else {
                  handleSubmitInventoryOrder(false);
                }
              }}
              text={
                tabs.find((tab) => tab.key === selectedTab)?.buttonText || ''
              }
              disabled={disabledActions}
              testid="review-button"
            />
          </div>
        </IonFooter>
      )}
    </IonPage>
  );
};

export default CountPlanInventory;
