export enum ShopQuoteTypeEnum {
  shopQuote = 'ShopQuoteReady',
}

export enum ShopQuoteIconEnum {
  shopQuote = 'screwdriver-wrench',
}

export enum ShopQuoteColorEnum {
  shopQuote = 'color-shopQuoteReady',
}

export enum ShopQuoteFilterEnum {
  shopQuote = 'ShopQuoteReady',
}
